import React, { useEffect, useState } from "react";
import axios from "axios";
import { Icon } from "@iconify/react";
import { Spin } from "antd";
import { useTranslation } from "react-i18next";

import { formatLocalizedDateTime } from "../shared/utils/dateTimeUtils";

import "./style.css";

const AirQuality = () => {
  const { t } = useTranslation();
  const [airQuality, setAirQuality] = useState(null);
  const [color, setColor] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const API_KEY = "0b4ca24c-28a4-4946-8205-2a492b701014";
  const COUNTRY = "Uzbekistan";
  const STATE = "Toshkent";
  const CITY = "Qibray";

  useEffect(() => {
    const fetchAirQuality = async () => {
      try {
        const response = await axios.get(
          `https://api.airvisual.com/v2/city?city=${CITY}&state=${STATE}&country=${COUNTRY}&key=${API_KEY}`
        );
        setAirQuality(response?.data?.data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };
    fetchAirQuality();
  }, []);

  useEffect(() => {
    if (airQuality) {
      const aqi = airQuality?.current?.pollution?.aqius;
      if (aqi <= 50) setColor("#A8E05F");
      else if (aqi <= 100) setColor("#FFDF58");
      else if (aqi <= 150) setColor("#FE9B57");
      else if (aqi <= 200) setColor("#FD6A6A");
      else if (aqi > 200) setColor("#A070B6");
    }
  }, [airQuality]);

  const renderIcon = (aqi) => {
    if (aqi <= 50)
      return <Icon icon={"mdi:face-happy"} style={{ fontSize: "48px" }} />;
    if (aqi <= 100)
      return <Icon icon={"mdi:face-neutral"} style={{ fontSize: "48px" }} />;
    if (aqi <= 150)
      return <Icon icon={"mdi:face-sad"} style={{ fontSize: "48px" }} />;
    if (aqi <= 200)
      return <Icon icon={"mdi:face-sad"} style={{ fontSize: "48px" }} />;
    if (aqi > 200)
      return <Icon icon={"mdi:face-cry"} style={{ fontSize: "48px" }} />;
  };

  const renderWeatherIcon = (condition) => {
    switch (condition) {
      case "01d":
      case "01n":
        return (
          <Icon
            color="#879BB2"
            icon="mdi:weather-sunny"
            style={{ fontSize: "25px" }}
          />
        );
      case "02d":
      case "02n":
        return (
          <Icon
            color="#879BB2"
            icon="mdi:weather-partly-cloudy"
            style={{ fontSize: "25px" }}
          />
        );
      case "03d":
      case "03n":
        return (
          <Icon
            color="#879BB2"
            icon="mdi:weather-cloudy"
            style={{ fontSize: "25px" }}
          />
        );
      case "04d":
      case "04n":
        return (
          <Icon
            color="#879BB2"
            icon="mdi:weather-cloudy"
            style={{ fontSize: "25px" }}
          />
        );
      case "09d":
      case "09n":
        return (
          <Icon
            color="#879BB2"
            icon="mdi:weather-rainy"
            style={{ fontSize: "25px" }}
          />
        );
      case "10d":
      case "10n":
        return (
          <Icon
            color="#879BB2"
            icon="mdi:weather-pouring"
            style={{ fontSize: "25px" }}
          />
        );
      case "11d":
      case "11n":
        return (
          <Icon
            color="#879BB2"
            icon="mdi:weather-lightning"
            style={{ fontSize: "25px" }}
          />
        );
      case "13d":
      case "13n":
        return (
          <Icon
            color="#879BB2"
            icon="mdi:weather-snowy"
            style={{ fontSize: "25px" }}
          />
        );
      case "50d":
      case "50n":
        return (
          <Icon
            color="#879BB2"
            icon="mdi:weather-fog"
            style={{ fontSize: "25px" }}
          />
        );
      default:
        return (
          <Icon
            color="#879BB2"
            icon="mdi:weather-sunny-alert"
            style={{ fontSize: "25px" }}
          />
        );
    }
  };

  return (
    <div className="air_information_wrapper" data-aos="fade-up">
      {error ? (
        <div>Ups xatolik yuz berdi</div>
      ) : (
        <>
          {loading ? (
            <Spin />
          ) : (
            <div>
              <h2>{t(airQuality?.state)}</h2>
              <p className="last_updatedtime">
                {t("lastUpdate")}: {"  "}
                {formatLocalizedDateTime(airQuality?.current.pollution.ts)}
              </p>
              <div style={{ background: color }} className="air_aqi_displayer">
                {renderIcon(airQuality?.current?.pollution?.aqius)}
                <p>AQI: {airQuality?.current?.pollution?.aqius}</p>
              </div>
              <div className="weather_information_wrapper">
                <div className="weather_information">
                  {renderWeatherIcon(airQuality?.current?.weather?.ic)}
                  <p>{airQuality?.current?.weather?.tp}°C</p>
                </div>
                |
                <div className="weather_information">
                  <Icon
                    icon="mdi:water"
                    color="#879BB2"
                    style={{ fontSize: "25px" }}
                  />
                  <p>{airQuality?.current?.weather?.hu}%</p>
                </div>
                |
                <div className="weather_information">
                  <Icon
                    icon="mdi:navigation-variant"
                    style={{ fontSize: "25px" }}
                    color="#879BB2"
                  />
                  <p>{airQuality?.current?.weather?.ws} m/s</p>
                </div>
              </div>
              <a
                href="https://www.iqair.com/ru/uzbekistan/toshkent-shahri/tashkent"
                target="_blank"
                className="navigate_to_iq_air"
                rel="noreferrer"
              >
                {t("goToAir")}
              </a>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AirQuality;
