import React, { useEffect } from "react";
import { Link, NavLink, useNavigate, useLocation } from "react-router-dom";
import { Icon } from "@iconify/react";
import { Dropdown } from "antd";
import { useTranslation } from "react-i18next";

import HamburgeMenu from "../hamburgeMenu/HamburgeMenu";
import oepLogo from "../../assets/logo.svg";
import { mainRoutes } from "../../routes/configs/mainRoutes";
import { useElectionData } from "../../context/ElectionContext";

const Navbar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { electionData } = useElectionData();

  const onClick = ({ key }) => {
    navigate(key);
  };

  useEffect(() => {
    if (location.hash === "#donation") {
      setTimeout(() => {
        const targetElement = document.getElementById("donation");
        window.scrollTo({
          top: targetElement?.offsetTop - 210,
        });
      }, 1000);
    }
  }, [location]);

  const routes = mainRoutes.map((route) => {
    if (route.text === "Saylov") {
      return { ...route, items: electionData };
    }
    return route;
  });

  const active = ({ isActive }) => ({
    color: isActive ? "var(--green-color)" : "var(--black-color)",
  });

  return (
    <div className="navbar_wrapper">
      <div className="content_wrapper navbar_container">
        <Link to="/">
          <img src={oepLogo} alt="oepLogo" className="navbar_logo" />
        </Link>
        <div className="navbar_items_wrapper">
          {routes.map(
            (item, index) =>
              item.visibleInNavbar && (
                <div key={index}>
                  {item.items ? (
                    <Dropdown
                      menu={{
                        items: item.items.map((subItem) => ({
                          ...subItem,
                          label: (
                            <p
                              className="navbat_dropdown_item"
                              key={subItem.key}
                            >
                              {t(subItem.label.props.children)}
                            </p>
                          ),
                        })),
                        onClick,
                      }}
                    >
                      <NavLink key={index} className="navbar_item">
                        {t(item.text)} <Icon icon="mingcute:down-fill" />
                      </NavLink>
                    </Dropdown>
                  ) : (
                    <NavLink
                      key={index}
                      className="navbar_item"
                      style={active}
                      to={item?.path}
                    >
                      {t(item.text)}
                    </NavLink>
                  )}
                </div>
              )
          )}
          <Link to="/#donation" className="navbar_sponsorship_button">
            {t("sponsorship")}
          </Link>
          <Link className="navbar_item" to="/login">
            {t("logIn")}
          </Link>
        </div>
        <div className="navbar_hamburger_wrapper">
          <HamburgeMenu />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
