import React, { Suspense, useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

import { mainRoutes } from "./configs/mainRoutes";
import { useFetchElectionData } from "./useElectionFetch";

const Pages = () => {
  const location = useLocation();
  const [routes, setRoutes] = useState(mainRoutes);
  const electionData = useFetchElectionData();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    setRoutes((prevRoutes) =>
      prevRoutes.map((route) => {
        if (route.text === "Saylov") {
          return { ...route, items: electionData };
        }
        return route;
      })
    );
  }, [electionData]);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        {routes.map((route, index) => (
          <Route
            path={route.path}
            element={route.element}
            exact={route?.exact ?? false}
            key={`1201${index}`}
          >
            {route.items?.map((subItem) => (
              <Route
                path={subItem.key}
                element={subItem?.element}
                exact={subItem?.exact ?? false}
                key={subItem.key}
              />
            ))}
          </Route>
        ))}
      </Routes>
    </Suspense>
  );
};

export default Pages;
