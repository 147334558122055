import { useGetEco } from "./eco-backend";

const usePageFetcher = (queryParam, query) => {
  const { isLoading, data, refetch, error } = useGetEco(queryParam, {
    is_all: true,
    ...query,
  });

  return {
    isLoading,
    data: data || [],
    refetch,
    error,
  };
};

export default usePageFetcher;
