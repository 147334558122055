import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import SectionTitle from "../shared/components/sectionTitle";
import Loader from "../shared/components/loader";

import usePageFetcher from "../repository/usePageFetcher";

import "./style.css";

const ElectionByYear = () => {
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const year = location.pathname.match(/\/election-(\d{4})/)[1];

  const { isLoading, data } = usePageFetcher("election/");

  const [election, setElection] = useState();

  useEffect(() => {
    const electionData = data?.find((value) => value?.year === Number(year));

    setElection(electionData);
  }, [year, data]);

  return (
    <div className="election_by_year">
      <div
        style={{ alignItems: "start" }}
        className="content_wrapper election_by_year_container"
      >
        <SectionTitle title={`${t("Saylov")} ${year}`} />
        {isLoading ? (
          <Loader />
        ) : (
          <div
            style={{ marginTop: "40px" }}
            className="election_by_year_container"
            dangerouslySetInnerHTML={{
              __html: election?.[`description_${i18n.language}`],
            }}
          ></div>
        )}
      </div>
    </div>
  );
};

export default ElectionByYear;
