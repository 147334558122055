import img1 from "./../../../shared/assets/latestNews1.png";
import img2 from "./../../../shared/assets/latestNews2.png";
// import img3 from "./../../../shared/assets/latestNews3.jpg";

import tipsUz from "./../../../shared/assets/tipsUz.png";
import tipsRu from "./../../../shared/assets/tipsUz.png";
import tipsEn from "./../../../shared/assets/tipsUz.png";

export const latesNewsData = [
  {
    id: 1,
    img: img1,
    title: "ENG KO’P MUHOKAMA BO’LGAN EKO-XABARLAR",
    hasImage: false,
    navigate: "/most-commented",
  },
  {
    id: 2,
    img: img2,
    title: "LOYIHALAR",
    hasImage: false,
    navigate: "/projects",
  },
  {
    id: 3,
    img: {
      ru: tipsRu,
      en: tipsEn,
      uz: tipsUz,
    },
    title: "Foydali maslahatlar va tavsiyalar",
    navigate: "/advices",
  },
];
