import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";

// import MainNews from "./components/MainNews";
import SectionTitle from "../../shared/components/sectionTitle";
import AdditionalNews from "./components/AdditionalNews";
import Announcements from "../announcements";

// import { newsData } from "./utils/fakeNewsData";

import "./style.css";

const News = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [onChangeBody, setOnChangeBody] = useState();

  return (
    <div className="news_wrapper">
      <div className="content_wrapper news_container">
        <SectionTitle
          // color={onChangeBody ? "var(--gray-color)" : "#0A5F22"}
          // secondColor={onChangeBody ? "#0A5F22" : "var(--gray-color)"}
          title="Yangiliklar"
          // secondTitle="E'lonlar"
          // onClick={() => setOnChangeBody(false)}
          // onSecondClick={() => setOnChangeBody(true)}
        />
        <div className="news_box_wrapper">
          {/* {onChangeBody ? (
            <Announcements />
          ) : (
           
          )} */}
          <div className="all_news_wrapper">
            {/* <MainNews newsData={newsData[0]} /> */}
            <AdditionalNews />
            <div
              data-aos="fade-up"
              onClick={() => navigate("/news")}
              className="see_more_button"
            >
              {t("more")} <Icon icon="bi:arrow-right" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default News;
