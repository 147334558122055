import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import SectionTitle from "../shared/components/sectionTitle";
import NewsCard from "../home/news/components/NewsCard";
import Loader from "../shared/components/loader";

import usePageFetcher from "../repository/usePageFetcher";
import { epochToTimeConverter } from "../shared/utils/dateTimeUtils";

import "./style.css";

const News = ({ navigatePath }) => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const { isLoading, data } = usePageFetcher("news/");

  return (
    <div className="all_type_news_wrapper">
      <div className="content_wrapper news_container">
        <SectionTitle title={"Yangiliklar"} />

        {isLoading ? (
          <Loader />
        ) : (
          <div className="news_box">
            {data?.map((news) => {
              return (
                <NewsCard
                  key={news.id}
                  image={news.img}
                  description={news[`title_${i18n.language}`]}
                  views={news.see}
                  postedDate={epochToTimeConverter(news?.posted_at)}
                  descriptionClassName={"all_news_description"}
                  imageClassName={"all_news_image"}
                  onClick={() => navigate(`${navigatePath}/${news?.id}`)}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default News;
