import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import NewsCard from "./NewsCard";

import usePageFetcher from "../../../repository/usePageFetcher";
import { epochToTimeConverter } from "../../../shared/utils/dateTimeUtils";

const AdditionalNews = () => {
  const { isLoading, data } = usePageFetcher("news/");
  const { i18n } = useTranslation();

  const navigate = useNavigate();

  return (
    <div className="news_box">
      {data?.slice(0, 3).map((news) => {
        return (
          <NewsCard
            descriptionClassName={"all_news_description"}
            imageClassName={"all_news_image"}
            onClick={() => navigate(`news/${news?.id}`)}
            key={news.id}
            image={news.img}
            description={news[`title_${i18n.language}`]}
            views={news.see}
            postedDate={epochToTimeConverter(news?.posted_at)}
          />
        );
      })}
    </div>
  );
};

export default AdditionalNews;
